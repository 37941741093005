
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
*{
    margin:0;
    padding:0;
    box-sizing:border-box;
    
}

body{
    font-family: "Papings",sans-serif
}
body {
    font-family: "Poppins", sans-serif, -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
  }

  html,
  body {
    scroll-behavior: smooth;
    overflow-x: hidden;
    
  }

